<!--
@author: binchen
@desc：index
@date: 2023-06-28 20:10:58
-->
<template>
  <ak-container card class="ak-card-table">
    <a-card>
      <a-form-model
        ref="searchForm"
        layout="inline"
        :model="searchForm"
        @submit="handleSearch"
        @submit.native.prevent
        :colon="false"
      >
        <a-form-model-item label="手机号" prop="phone">
          <a-input allowClear v-model="searchForm.phone" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="店铺名称" prop="shopName">
          <a-input
            allowClear
            v-model="searchForm.shopName"
            placeholder="请输入"
          />
        </a-form-model-item>

        <a-form-model-item label="昵称" prop="nickName">
          <a-input
            allowClear
            v-model="searchForm.nickName"
            placeholder="请输入"
          />
        </a-form-model-item>
        <div style="margin-top: 20px">
            <a-form-model-item label="入驻时间" prop="settleTime">
          <a-range-picker
            v-model="date"
            show-time
            class="settime"
            :placeholder="['开始日期', '结束日期']"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            @change="changeDate"
          />
        </a-form-model-item>
        <a-button type="primary" class="ML12" @click="handleSearch"
          >查询</a-button
        >
        <a-button class="ML12" @click="onResetForm">重置</a-button>
        </div>
      
      </a-form-model>
    </a-card>

    <a-table
      :pagination="pagination"
      @change="changePage"
      :columns="columns"
      :data-source="tableData"
      style="margin-top: 20px"
    >
      <div slot="logo" slot-scope="text">
        <img class="logo" :src="ossUrl(text)" alt="" />
      </div>
      <div slot="bindPersonal" slot-scope="text" class="bindPersonal">
        <img class="logo" :src="ossUrl(text.headImage)" alt=""  />
        <div>
          <div class="name">{{ text.name }}</div>
          <div class="phone">{{ text.phone }}</div>
        </div>
      </div>
      <div slot="state" slot-scope="text">
        <span v-if="text == 0">待审核</span>
        <span v-if="text == 1">已通过</span>
        <span v-if="text == 2" style="color: red">未通过</span>
      </div>
      <div slot="action" slot-scope="text">
        <!-- <a-button type="link" class="ML12" @click="handleSearch">详情</a-button> -->
        <a-button type="link" class="ML12" @click="apply(text)" v-if="text.state==='0'">审核</a-button>
      </div>
    </a-table>
    <a-modal v-model="visible" ok-text="确认" cancel-text="取消" @ok="submit">
      <div slot="title" class="modaltitle">
        <a-icon type="info-circle" theme="filled" class="exclamation-circle" />
        <h4>审核认证信息</h4>
      </div>
      <a-radio-group name="radioGroup" v-model="submitForm.state">
        <a-radio :value="1"> 认证通过 </a-radio>
        <a-radio :value="2"> 未通过认证 </a-radio>
      </a-radio-group>
    </a-modal>
  </ak-container>
</template>

<script>
import { getclaimlist, claimCheck } from "@/api/user/settle.js";
const columns = [
  {
    title: "LOGO",
    dataIndex: "logo",
    key: "logo",
    scopedSlots: { customRender: "logo" },
  },
  {
    title: "认领店铺名称",
    dataIndex: "shopName",
    key: "shopName",
  },
  {
    title: "认领用户",
    dataIndex: "bindPersonal",
    key: "bindPersonal",
    scopedSlots: { customRender: "bindPersonal" },
  },
  {
    title: "状态",
    key: "state",
    dataIndex: "state",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "申请认领时间",
    dataIndex: "claimTime",
    key: "claimTime",
  },
  {
    title: "入驻时间",
    dataIndex: "createdTime",
    key: "createdTime",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "ClaimManagement",
  data() {
    return {
      visible: false,
      columns,
      date: [],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        current: 1,
      },
      searchForm: {
        phone: "",
        nickName: "",
        shopName: "",
        createdTimeEnd: "",
        createdTimeStart: "",
        pageSize: 10,
        page: 1,
      },
      submitForm: {
        state: 1,
        id: null,
      },
    };
  },
  created() {
    this.handleSearch();
  },
  mounted() {},
  methods: {
    
    handleSearch() {
      getclaimlist(this.searchForm).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.pagination.total = res.data.total
        }
      });
    },
    changePage(val) {
      this.searchForm.page = val.current;
      this.searchForm.pageSize = val.pageSize;
      this.pagination.pageSize = val.pageSize;
      this.pagination.current = val.current;
      this.handleSearch();
    },
    changeDate(value) {
      this.searchForm.createdTimeStart = value[0];
      this.searchForm.createdTimeEnd = value[1];
    },
    onResetForm() {
      this.$refs.searchForm.resetFields();
      this.searchForm = this.$options.data.call(this).searchForm;
      this.date = [];
      this.handleSearch();
    },
    apply(row) {
        console.log(row)
      if (!row.id) return;
      this.submitForm.id = row.id;
      this.visible = true;
    },
    submit() {
      claimCheck(this.submitForm).then((res) => {
        if(res.code === 0){
            this.$message.success('操作成功')
            this.handleSearch()
            this.visible = false
            this.submitForm.state = 1
        }else{
             this.$message.error('操作失败')
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.logo {
  width: 60px;
  height: 60px;
}
.bindPersonal {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: center;
}
.modaltitle {
  display: flex;
  .exclamation-circle {
    color: #faad14;
    font-size: 20px;
    margin-right: 10px;
  }
}
</style>
